import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import React, { useEffect, useState } from "react";

import { COUNTER_API_URL } from 'config';
import { PageProps } from "gatsby";
import { questionsBroker } from "../questionsBroker";
import { questionsEmployer } from "../questionsEmployer";

type DataProps = unknown;

const Dif = "—";

const MetricsPage: React.FC<PageProps<DataProps>> = () => {

  const [metrics, setMetrics] = useState([]);
  const [welcomeScreenViews, setWelcomeScreenViews] = useState(0);

 const getMetrics = async () => {
    const fetchedMetrics = await fethMetrics();
    const welcomeSceen: any = fetchedMetrics[0];
    const welcomeViews: number = welcomeSceen.views || 0;
    fetchedMetrics.shift();
    setWelcomeScreenViews(welcomeViews);
    setMetrics(fetchedMetrics)
  }

  const fethMetrics = async () => {
    const host = window.location.href;
    const env = host.includes('wellnet-survey.com') || host.includes('survey.wellnet.com') ? '.prod' : '.test';
    const role = host.includes('employer') ? 'employer' : 'broker';
    const questions = role === 'employer' ? questionsEmployer : questionsBroker;

    const unresolved = questions.map(async(question, i) => {
      let response = await fetch(COUNTER_API_URL+'/pageview-get?page='+env+'-'+role+'step-'+question.uid)
      let responseObject = await response.json();
      const countObjet = {'name': responseObject.page, 'views': responseObject.visits  || 0};
      return countObjet;
    })
    let resolved: any = null
    try {
      resolved = await Promise.all(unresolved)
    } catch (error) {
      console.log('error getting metrics')
    } 

    console.log(resolved, 'resolved')
    return resolved;
  }

  useEffect(()=> {
    getMetrics();
  }, [])

  return (
    <div>
        <h1>Metrics</h1>
        <button onClick={() => getMetrics()}>Update</button>
        {
          metrics.length > 0 &&
        <>
        <ResponsiveContainer width={'100%'} height={700}>         
        <AreaChart stackOffset={'wiggle'} width={730} height={250} data={metrics}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorViews" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area type="monotone" dataKey="views" stroke="#8884d8" fillOpacity={1} fill="url(#colorViews)" />
        </AreaChart>
        </ResponsiveContainer>
        </>
        }
    </div>
  );
};

export default MetricsPage;

interface IAnswers {
  [key: string]: string | number;
}
